import React from 'react';
import cx from 'classnames';
import { Grid } from '../../Grid';
import { GridCell } from '../../GridCell';
import { OptionsType } from '../AdFooter';
import styles from './style.module.css';

interface Props {
  adOptions: OptionsType;
  onDelete: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  id: any;
}
export const ActiveFooter = ({ adOptions, onDelete, id }: Props) => {
  const { views, contacts } = adOptions;

  const borderedDivClasses = cx('lh-copy f12 pv8 bt', styles.borderBrownGrey);
  const contactsDivClasses = cx('tc bl br boulder', styles.borderBrownGrey);
  return (
    <Grid className={borderedDivClasses}>
      <GridCell width={5 / 12}>
        <div className="boulder tc fw3">
          <span className="fw7">{views}</span> visualizações
        </div>
      </GridCell>
      <GridCell className={contactsDivClasses} width={4 / 12}>
        <div className="boulder tc fw3">
          <span className="fw7">{contacts}</span> contatos
        </div>
      </GridCell>
      <GridCell className="tc underline" width={3 / 12}>
        <span className="fw7 abbey" id={id} onClick={onDelete}>
          EXCLUIR
        </span>
      </GridCell>
    </Grid>
  );
};
