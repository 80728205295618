import React from 'react';
import { Content } from './Contents/Content';
import { ContentProcessing } from './Contents/ContentProcessing';
import { WrapperContent } from './Contents/WrapperContent';

export interface Options {
  adStatus: string;
  contacts: number;
  createdAt: string;
  statusColor: string;
  thumbUrl?: string;
  title: string;
  value: string;
  views: number;
  year: string;
  adCode: number;
  newPart?: boolean;
}

interface Props {
  adStatus: 'processamento' | 'rascunho' | 'ativo' | 'inativo';
  adOptions: Options;
  isAdmin: boolean;
  days?: number;
  clickReactivate: () => void;
  clickDraft: () => void;
  typeAd?: number;
  newPart?: boolean;
}

export const AdContent = ({
  adStatus,
  adOptions,
  days,
  clickReactivate,
  clickDraft,
  isAdmin,
  typeAd,
}: Props) => {
  switch (adStatus) {
    case 'processamento':
      return <ContentProcessing adOptions={adOptions} />;

    case 'inativo':
      return isAdmin ? (
        <Content
          adOptions={adOptions}
          days={days}
          adStatus={adStatus}
          isAdmin={isAdmin}
          typeAd={typeAd}
        />
      ) : (
        <WrapperContent buttonText="REATIVAR ANÚNCIO" onClick={clickReactivate}>
          <Content
            adOptions={adOptions}
            days={days}
            adStatus={adStatus}
            isAdmin={isAdmin}
            typeAd={typeAd}
          />
        </WrapperContent>
      );

    case 'rascunho':
      return (
        <WrapperContent buttonText="RETOMAR" onClick={clickDraft}>
          <Content
            adOptions={adOptions}
            days={days}
            adStatus={adStatus}
            isAdmin={isAdmin}
            typeAd={typeAd}
          />
        </WrapperContent>
      );
    default:
      return (
        <Content
          adOptions={adOptions}
          days={days}
          adStatus={adStatus}
          isAdmin={isAdmin}
          typeAd={typeAd}
        />
      );
  }
};
